import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingState } from '#/src/constants/loading-state';
import { SupplyEditingInfo } from '#/src/types/supply';

import { fetchSuppliesEditing, updateSupplies } from './actions';

export type EditedDebtorType = {
    contract: string;
    receiptDate?: string;
    delayPeriod?: string;
};

export type SuppliesEditingState = {
    loadingState: LoadingState;
    editedDebtors: Record<string, EditedDebtorType>;
    warningModalIsOpen: boolean;
    editModalIsOpen: boolean;
    editingSuppliesList: SupplyEditingInfo[];
    debtorsDictionary: Record<string, string>;
};

const initialState: SuppliesEditingState = {
    loadingState: 'unknown',
    editedDebtors: {},
    warningModalIsOpen: false,
    editModalIsOpen: false,
    editingSuppliesList: [],
    debtorsDictionary: {},
};

export const {
    reducer: suppliesEditingReducer,
    name,
    actions,
} = createSlice({
    name: 'suppliesEditing',
    initialState,
    reducers: {
        clearState(state) {
            state.loadingState = 'unknown';
            state.editedDebtors = {};
            state.warningModalIsOpen = false;
            state.editModalIsOpen = false;
            state.editingSuppliesList = [];
            state.debtorsDictionary = {};
        },
        setAgreementForDebtor(
            state,
            action: PayloadAction<{
                debtorTin: string;
                agreement: string;
            }>,
        ) {
            state.editedDebtors[action.payload.debtorTin].contract = action.payload.agreement;
        },
        setDelayPeriodForDebtor(
            state,
            action: PayloadAction<{ debtorTin: string; delay: EditedDebtorType['delayPeriod'] }>,
        ) {
            state.editedDebtors[action.payload.debtorTin].delayPeriod = action.payload.delay;
        },
        setReceiptDateForDebtor(
            state,
            action: PayloadAction<{
                debtorTin: string;
                receiptDate: EditedDebtorType['receiptDate'];
            }>,
        ) {
            state.editedDebtors[action.payload.debtorTin].receiptDate = action.payload.receiptDate;
        },
        changeEditModalState(state, action: PayloadAction<boolean>) {
            state.editModalIsOpen = action.payload;
        },
        changeWarningModalState(state, action: PayloadAction<boolean>) {
            state.warningModalIsOpen = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSuppliesEditing.pending, (state) => {
            state.loadingState = 'loading';
        });
        builder.addCase(fetchSuppliesEditing.fulfilled, (state, action) => {
            state.loadingState = 'success';
            state.editedDebtors = action.payload.editedDebtors;
            state.warningModalIsOpen = action.payload.warningModalIsOpen;
            state.editModalIsOpen = action.payload.editModalIsOpen;
            state.editingSuppliesList = action.payload.editingSuppliesList;
            state.debtorsDictionary = action.payload.debtorsDictionary;
        });
        builder.addCase(fetchSuppliesEditing.rejected, (state) => {
            state.loadingState = 'error';
        });
        builder.addCase(updateSupplies.pending, (state) => {
            state.loadingState = 'loading';
        });
        builder.addCase(updateSupplies.fulfilled, (state) => {
            state.loadingState = 'success';
            state.editModalIsOpen = false;
        });
        builder.addCase(updateSupplies.rejected, (state) => {
            state.loadingState = 'error';
        });
    },
});

import { SupplyDto, SupplyEditingInfo } from '#/src/types/supply';
import { EditedDebtorType } from '#/src/store/supplies/supplies-editing/reducer';

export function getSuppliesEditingData(
    supplies: SupplyDto[],
    suppliesEditing: SupplyEditingInfo[],
) {
    const availableSuppliesForEditing: Record<number, boolean> = {};

    for (let i = 0; i < suppliesEditing.length; i++) {
        availableSuppliesForEditing[suppliesEditing[i].supplyId] =
            suppliesEditing[i].supplyDelayPeriodEditable ||
            suppliesEditing[i].supplyReceiptDateEditable ||
            suppliesEditing[i].supplyAgreementEditable;
    }

    const editedDebtors: Record<string, EditedDebtorType> = {};
    const debtorsDictionary: Record<string, string> = {};
    const editingSuppliesList: SupplyEditingInfo[] = [];

    for (let j = 0; j < supplies.length; j++) {
        const { id, debtorTin, debtorName } = supplies[j];
        const { supplyDelayPeriodEditable, supplyReceiptDateEditable, supplyAgreementEditable } =
            suppliesEditing[j] || {};

        if (id && debtorTin && availableSuppliesForEditing[id]) {
            editedDebtors[debtorTin] = {
                contract: '',
                delayPeriod: '',
                receiptDate: '',
            };
            debtorsDictionary[debtorTin] = debtorName || '';
            editingSuppliesList.push({
                id,
                debtorTin,
                supplyId: id,
                supplyDelayPeriodEditable,
                supplyReceiptDateEditable,
                supplyAgreementEditable,
            });
        }
    }

    return { editedDebtors, editingSuppliesList, debtorsDictionary };
}

import { createSelector } from 'reselect';
import { format } from 'date-fns';

import { ApplicationState } from '#/src/store/reducer';
import { SupplyUpdateEntity } from '#/src/types/supply';

import { suppliesModelsSelector } from '../selectors';

export const suppliesEditingStateSelector = (state: ApplicationState) =>
    suppliesModelsSelector(state).suppliesEditing;

export const editedDebtorsSelector = (state: ApplicationState) =>
    suppliesEditingStateSelector(state).editedDebtors;

export const debtorsDictionarySelector = (state: ApplicationState) =>
    suppliesEditingStateSelector(state).debtorsDictionary;

export const editingSuppliesListSelector = (state: ApplicationState) =>
    suppliesEditingStateSelector(state).editingSuppliesList;

export const editingSupplySelector = (currentDebtorTin: string) => (state: ApplicationState) =>
    suppliesEditingStateSelector(state).editingSuppliesList.find(
        ({ debtorTin }) => debtorTin === currentDebtorTin,
    );

export const editModalIsOpenSelector = (state: ApplicationState) =>
    suppliesEditingStateSelector(state).editModalIsOpen;

export const warningModalIsOpenSelector = (state: ApplicationState) =>
    suppliesEditingStateSelector(state).warningModalIsOpen;

export const suppliesEditingIsLoadingSelector = createSelector(
    suppliesEditingStateSelector,
    (state) => state.loadingState === 'loading',
);

export const editedDebtorsListSelector = createSelector(editedDebtorsSelector, (data) =>
    Object.keys(data).map((debtorTin) => ({ debtorTin, supplyAgreementNumber: data[debtorTin] })),
);

export const savingAvailableSelector = createSelector(editedDebtorsListSelector, (list) =>
    list.some(({ supplyAgreementNumber }) => supplyAgreementNumber),
);

export const editedSuppliesListSelector = createSelector(
    editingSuppliesListSelector,
    editedDebtorsSelector,
    (supplies, values) =>
        supplies.reduce((arr, supply) => {
            if (supply.id && supply.debtorTin && values[supply.debtorTin]) {
                const debtorValues = values[supply.debtorTin];
                const updatedSupply = {
                    id: supply.id,
                    ...(debtorValues.contract && { supplyAgreementNumber: debtorValues.contract }),
                    ...(debtorValues.delayPeriod && { delayPeriod: debtorValues.delayPeriod }),
                    ...(debtorValues.receiptDate && {
                        receiptDate: format(String(debtorValues.receiptDate), 'DD.MM.YYYY'),
                    }),
                };

                return arr.concat(updatedSupply);
            }

            return arr;
        }, [] as SupplyUpdateEntity[]),
);

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Grid } from '@alfalab/core-components/grid';

import { reportFilters } from '#/src/constants/reports';
import { reportFiltersSelector } from '#/src/store/reports-xls/report-filters/selectors';

import DatesFilter from './dates-filter';
import DebtorFilter from './debtor-filter';
import DocumentNumberFilter from './document-number-filter';
import GeneralAgreementFilter from './general-agreement-filter';
import SupplyNumFilter from './supply-num-filter';

import './reports-xls-filters.css';

const gridColCommonWidth = { desktop: 9, tablet: 9, mobile: 12 };

const cn = createCn('reports-xls-filters');
const gridColFilterClassName = cn('filter');

const ReportsXlsFilters: FC = () => {
    const { reportName } = useSelector(reportFiltersSelector);

    if (reportName) {
        const currentFilters = reportFilters[reportName];

        if (currentFilters?.length) {
            return (
                <Grid.Row>
                    <Grid.Col width={gridColCommonWidth}>
                        <Grid.Row>
                            {currentFilters
                                .map((filterName, index) => {
                                    let filterElement;

                                    switch (filterName) {
                                        case 'debtorId':
                                            filterElement = <DebtorFilter />;
                                            break;
                                        case 'dateFrom':
                                        case 'dateTo':
                                        case 'dateFrom;dateTo':
                                            filterElement = <DatesFilter type={filterName} />;
                                            break;
                                        case 'documentNumber':
                                            filterElement = <DocumentNumberFilter />;
                                            break;
                                        case 'generalAgreementId':
                                            filterElement = <GeneralAgreementFilter />;
                                            break;
                                        case 'supplyNum':
                                            filterElement = <SupplyNumFilter />;
                                            break;
                                    }

                                    return filterElement ? (
                                        <Grid.Col
                                            key={filterName}
                                            width={
                                                index === currentFilters.length - 1 &&
                                                currentFilters.length % 2 === 1
                                                    ? 12
                                                    : {
                                                          desktop: 6,
                                                          tablet: { s: 12, m: 6 },
                                                          mobile: 12,
                                                      }
                                            }
                                            className={gridColFilterClassName}
                                        >
                                            {filterElement}
                                        </Grid.Col>
                                    ) : null;
                                })
                                .filter(Boolean)}
                        </Grid.Row>
                    </Grid.Col>
                </Grid.Row>
            );
        }
    }

    return null;
};

export default ReportsXlsFilters;

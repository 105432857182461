import { ReportFilterKeys, ReportNames } from '#/src/types/reports';

export const reportSortIndex: Record<ReportNames, number> = {
    MONGER_DEBTOR_LIST: 100,
    MONGER_SUPPLY_LIST: 99,
    MONGER_DEBTOR_PAYMENTS: 6,
    MONGER_REGISTERED_SUPPLIES: 3,
    MONGER_FINANCING_TRANSFER: 4,
    MONGER_RESERVES_TRANSFER: 8,
    MONGER_PAYMENTS_COMMISIONS_RESTS: 7,
    MONGER_DETAILS_OF_FINANCING_COMMISSION: 9,
    MONGER_INCOMING_DEBTOR_PAYMENTS: 5,
    MONGER_DEBTS_OF_DEBTORS: 12,
    MONGER_DETAILS_FOR_DEBTS_OF_DEBTORS: 12,
    MONGER_LIMITS: 1,
    MONGER_FINANCING_TAXES: 2,
    MONGER_REPAYMENTS: 10,
    MONGER_TURNOVER: 13,
    MONGER_STATISTICS: 14,
    MONGER_DETAILS_FOR_INVOICE: 11,
    DEBTOR_MONGER_LIST: 100,
    DEBTOR_SUPPLY_LIST: 99,
    DEBTOR_REGISRERED_SUPPLIES: 99,
    DEBTOR_INCOMING_PAYMENTS: 99,
    DEBTOR_PAYMENTS: 99,
    DEBTOR_ACCRUED_COMMISSION: 99,
    DEBTOR_DEBT_DEBTOR_TO_SUPPLY: 99,
    DEBTOR_PAYMENT_REFUNDED_DEBTOR: 99,
    DEBTOR_TARIFFS_DEBTOR: 99,
    DEBTOR_TARIFFS_DEBTOR_FOR_SELLER: 99,
};

export const reportNames: Record<ReportNames, string> = {
    MONGER_DEBTOR_LIST: 'Информация по дебиторам',
    MONGER_SUPPLY_LIST: 'Список поставок',
    MONGER_DEBTOR_PAYMENTS: 'Распределение поступивших платежей',
    MONGER_REGISTERED_SUPPLIES: 'Принятые поставки/уступленные денежные требования',
    MONGER_FINANCING_TRANSFER: 'Выданное/погашенное финансирование',
    MONGER_RESERVES_TRANSFER: 'Перечисленный остаток от поступивших платежей',
    MONGER_PAYMENTS_COMMISIONS_RESTS: 'Детальное распределение поступивших платежей',
    MONGER_DETAILS_OF_FINANCING_COMMISSION: 'Расчет удержанной комиссии за финансирование',
    MONGER_INCOMING_DEBTOR_PAYMENTS: 'Отчет по входящим платежам по П/П',
    MONGER_DEBTS_OF_DEBTORS: 'Дебиторская задолженность по ЮЛ',
    MONGER_DETAILS_FOR_DEBTS_OF_DEBTORS:
        'Дебиторская задолженность по принятым поставкам/уступленным денежным требованиям',
    MONGER_LIMITS: 'Лимит',
    MONGER_FINANCING_TAXES: 'Ставки за финансирование',
    MONGER_REPAYMENTS: 'Возвраты платежей',
    MONGER_TURNOVER: 'Оборот дебиторской задолженности по ЮЛ',
    MONGER_STATISTICS: 'Статистика по дебиторской задолженности',
    MONGER_DETAILS_FOR_INVOICE: 'Расшифровка к счету-фактуре',
    DEBTOR_MONGER_LIST: 'Информация по поставщикам',
    DEBTOR_SUPPLY_LIST: 'Список поставок',
    DEBTOR_REGISRERED_SUPPLIES: 'Принятые поставки/уступленные денежные требования',
    DEBTOR_INCOMING_PAYMENTS: 'Отчет по входящим платежам по П/П',
    DEBTOR_PAYMENTS: 'Распределение поступивших платежей',
    DEBTOR_ACCRUED_COMMISSION: 'Расчет по начисленным и оплаченным комиссиям',
    DEBTOR_DEBT_DEBTOR_TO_SUPPLY:
        'Кредиторская задолженность по принятым поставкам/уступленным денежным требованиям',
    DEBTOR_PAYMENT_REFUNDED_DEBTOR: 'Возвраты платежей',
    DEBTOR_TARIFFS_DEBTOR: 'Тарифы дебитора по соглашению',
    DEBTOR_TARIFFS_DEBTOR_FOR_SELLER: 'Тарифы дебитора за продавца',
};

export const reportFileNames: Record<ReportNames, string> = {
    MONGER_DEBTOR_LIST: 'Информация по дебиторам',
    MONGER_SUPPLY_LIST: 'Список поставок',
    MONGER_DEBTOR_PAYMENTS: 'Распределение поступивших платежей',
    MONGER_REGISTERED_SUPPLIES: 'Принятые поставки',
    MONGER_FINANCING_TRANSFER: 'Выданное_погашенное финансирование',
    MONGER_RESERVES_TRANSFER: 'Перечисленный остаток от поступивших платежей',
    MONGER_PAYMENTS_COMMISIONS_RESTS: 'Детальное распределение поступивших платежей',
    MONGER_DETAILS_OF_FINANCING_COMMISSION: 'Расчет удержанной комиссии за финансирование',
    MONGER_INCOMING_DEBTOR_PAYMENTS: 'Отчет по входящим платежам по ПП',
    MONGER_DEBTS_OF_DEBTORS: 'Дебиторская задолженность по ЮЛ',
    MONGER_DETAILS_FOR_DEBTS_OF_DEBTORS: 'Дебиторская задолженность по принятым поставкам',
    MONGER_LIMITS: 'Лимит',
    MONGER_FINANCING_TAXES: 'Ставки за финансирование',
    MONGER_REPAYMENTS: 'Возвраты платежей',
    MONGER_TURNOVER: 'Оборот дебиторской задолженности по ЮЛ',
    MONGER_STATISTICS: 'Статистика по дебиторской задолженности',
    MONGER_DETAILS_FOR_INVOICE: 'Расшифровка к счету-фактуре',
    DEBTOR_MONGER_LIST: 'Информация по поставщикам',
    DEBTOR_SUPPLY_LIST: 'Список поставок',
    DEBTOR_REGISRERED_SUPPLIES: 'Принятые поставки',
    DEBTOR_INCOMING_PAYMENTS: 'Отчет по входящим платежам по ПП',
    DEBTOR_PAYMENTS: 'Распределение поступивших платежей',
    DEBTOR_ACCRUED_COMMISSION: 'Расчет по начисленным и оплаченным комиссиям',
    DEBTOR_DEBT_DEBTOR_TO_SUPPLY: 'Кредиторская задолженность по принятым поставкам',
    DEBTOR_PAYMENT_REFUNDED_DEBTOR: 'Возвраты платежей',
    DEBTOR_TARIFFS_DEBTOR: 'Тарифы дебитора по соглашению',
    DEBTOR_TARIFFS_DEBTOR_FOR_SELLER: 'Тарифы дебитора за продавца',
};

export const reportFilters: Record<ReportNames, Array<ReportFilterKeys | 'dateFrom;dateTo'>> = {
    // порядок важен для отрисовки очередности полей
    MONGER_DEBTOR_LIST: [],
    MONGER_SUPPLY_LIST: [],
    MONGER_DEBTOR_PAYMENTS: ['dateFrom;dateTo'],
    MONGER_REGISTERED_SUPPLIES: ['dateFrom;dateTo'],
    MONGER_FINANCING_TRANSFER: ['debtorId', 'dateFrom;dateTo', 'documentNumber'],
    MONGER_RESERVES_TRANSFER: ['debtorId', 'dateFrom;dateTo', 'documentNumber'],
    MONGER_PAYMENTS_COMMISIONS_RESTS: ['debtorId', 'dateFrom;dateTo', 'documentNumber'],
    MONGER_DETAILS_OF_FINANCING_COMMISSION: ['debtorId', 'dateFrom;dateTo', 'documentNumber'],
    MONGER_INCOMING_DEBTOR_PAYMENTS: ['dateFrom;dateTo'],
    MONGER_DEBTS_OF_DEBTORS: ['debtorId', 'dateFrom', 'supplyNum'],
    MONGER_DETAILS_FOR_DEBTS_OF_DEBTORS: ['debtorId', 'dateFrom', 'supplyNum'],
    MONGER_LIMITS: ['debtorId', 'dateTo'],
    MONGER_FINANCING_TAXES: ['generalAgreementId', 'dateFrom;dateTo'],
    MONGER_REPAYMENTS: ['dateFrom;dateTo'],
    MONGER_TURNOVER: ['dateFrom;dateTo'],
    MONGER_STATISTICS: ['dateFrom;dateTo'],
    MONGER_DETAILS_FOR_INVOICE: ['debtorId', 'dateFrom;dateTo', 'documentNumber'],
    DEBTOR_MONGER_LIST: [],
    DEBTOR_SUPPLY_LIST: [],
    DEBTOR_REGISRERED_SUPPLIES: ['debtorId', 'dateFrom;dateTo'],
    DEBTOR_INCOMING_PAYMENTS: ['dateFrom;dateTo'],
    DEBTOR_PAYMENTS: ['debtorId', 'dateFrom;dateTo'],
    DEBTOR_ACCRUED_COMMISSION: ['debtorId', 'dateFrom;dateTo', 'documentNumber'],
    DEBTOR_DEBT_DEBTOR_TO_SUPPLY: ['debtorId', 'dateFrom', 'documentNumber'],
    DEBTOR_PAYMENT_REFUNDED_DEBTOR: ['debtorId', 'dateFrom;dateTo'],
    DEBTOR_TARIFFS_DEBTOR: ['dateFrom;dateTo'],
    DEBTOR_TARIFFS_DEBTOR_FOR_SELLER: ['debtorId', 'dateFrom'],
};

export const requiredReportFilterKeys: Array<ReportFilterKeys | 'dateFrom;dateTo'> = [
    'dateFrom;dateTo',
    'dateFrom',
    'dateTo',
];
